export const localeCodes = ["en","sv","uk"]

export const localeMessages = {
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "lang_locales_locales_en_json" */) }],
  "sv": [{ key: "../locales/sv.json", load: () => import("../locales/sv.json" /* webpackChunkName: "lang_locales_locales_sv_json" */) }],
  "uk": [{ key: "../locales/uk.json", load: () => import("../locales/uk.json" /* webpackChunkName: "lang_locales_locales_uk_json" */) }],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.bundle = Object({"compositionOnly":true})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":true,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  const vueI18n = await vueI18nConfigLoader((() => import("../i18n.config.ts?hash=e13d72a8&config=1" /* webpackChunkName: "__i18n_config_ts_e13d72a8" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.vueI18n.messages ??= {}
  nuxtI18nOptions.locales = [Object({"code":"en","iso":"en-US","domain":"project1882.org","files":["locales/en.json"],"hashes":["2fc1afd4"],"types":["static"]}),Object({"code":"sv","iso":"sv-SE","domain":"djurensratt.se","files":["locales/sv.json"],"hashes":["f9a0d3d5"],"types":["static"]}),Object({"code":"uk","iso":"uk-UA","domain":"ua.project1882.org","files":["locales/uk.json"],"hashes":["84ac9b4f"],"types":["static"]})]
  nuxtI18nOptions.defaultLocale = "sv"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "no_prefix"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "locales"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  nuxtI18nOptions.parallelPlugin = false
  nuxtI18nOptions.i18nModules = []
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),bundle: Object({"compositionOnly":true}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false,parallelPlugin: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","iso":"en-US","domain":"project1882.org","files":["locales/en.json"],"hashes":["2fc1afd4"],"types":["static"]}),Object({"code":"sv","iso":"sv-SE","domain":"djurensratt.se","files":["locales/sv.json"],"hashes":["f9a0d3d5"],"types":["static"]}),Object({"code":"uk","iso":"uk-UA","domain":"ua.project1882.org","files":["locales/uk.json"],"hashes":["84ac9b4f"],"types":["static"]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const parallelPlugin = false
