import { default as _91_46_46_46slug_93GKZLsqnV4IMeta } from "/opt/build/repo/apps/refresh/pages/[...slug].vue?macro=true";
import { default as searchd2pOwP5Ef7Meta } from "/opt/build/repo/apps/refresh/pages/search.vue?macro=true";
import { default as rejectUfEZoEoWvmMeta } from "/opt/build/repo/apps/refresh/pages/swish/reject.vue?macro=true";
import { default as successgwZfqiZu1lMeta } from "/opt/build/repo/apps/refresh/pages/swish/success.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93GKZLsqnV4IMeta?.name ?? "slug",
    path: _91_46_46_46slug_93GKZLsqnV4IMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93GKZLsqnV4IMeta || {},
    alias: _91_46_46_46slug_93GKZLsqnV4IMeta?.alias || [],
    redirect: _91_46_46_46slug_93GKZLsqnV4IMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/apps/refresh/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: searchd2pOwP5Ef7Meta?.name ?? "search",
    path: searchd2pOwP5Ef7Meta?.path ?? "/search",
    meta: searchd2pOwP5Ef7Meta || {},
    alias: searchd2pOwP5Ef7Meta?.alias || [],
    redirect: searchd2pOwP5Ef7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/apps/refresh/pages/search.vue").then(m => m.default || m)
  },
  {
    name: rejectUfEZoEoWvmMeta?.name ?? "swish-reject",
    path: rejectUfEZoEoWvmMeta?.path ?? "/swish/reject",
    meta: rejectUfEZoEoWvmMeta || {},
    alias: rejectUfEZoEoWvmMeta?.alias || [],
    redirect: rejectUfEZoEoWvmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/apps/refresh/pages/swish/reject.vue").then(m => m.default || m)
  },
  {
    name: successgwZfqiZu1lMeta?.name ?? "swish-success",
    path: successgwZfqiZu1lMeta?.path ?? "/swish/success",
    meta: successgwZfqiZu1lMeta || {},
    alias: successgwZfqiZu1lMeta?.alias || [],
    redirect: successgwZfqiZu1lMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/apps/refresh/pages/swish/success.vue").then(m => m.default || m)
  }
]