import { reactive } from 'vue';

import { SECTION_BLOCK } from '../../utils/constants';

import { getDataComponent } from './getDataComponent';

export const store = reactive({
  included: [],
});

export const useData = (response, config: { cmsBasePath: string, cloudname: string }, t) => {
  store.included = response?.included;

  const getChildren = id => {
    return response?.included
      .filter(
        child =>
          child.attributes.behavior_settings?.layout_paragraphs?.parent_uuid === id,
      )
      .map(item => {
        return getDataComponent(item, config, t);
      });
  };

  if (!response?.included) {
    return [];
  }

  const sections = response?.included
    .filter(item => item.type === SECTION_BLOCK)
    .map(section => ({
      background: section.attributes.field_background || 'white',
      space: section.attributes.field_space || 'with-space',
      children: getChildren(section.id),
    }));

  return sections;
};
