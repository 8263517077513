<template>
  <Header :show-menu-button="false" />
  <Container class="mb-16 mt-4 space-y-12 lg:mt-52 lg:space-y-28">
    <IntroBlock :data="data" />
  </Container>
</template>
<script setup>
import Header from '@/components/elements/Header.vue';
import { IntroBlock, Container } from 'refresh-ui';
const data = {
  subtitle: 'Djurens Rätt',
  title: 'Något har gått fel',
  copy: '<p>Försök gärna ladda sidan igen, eller <a href="/search">sök här</a>. Om problemet kvarstår, kontakta oss på <a href="mailto:info@djurensratt.se">info@djurensratt.se</a></p>',
  color: 'blue',
};
</script>
