import { getRelationships } from './getRelationships';
import { getVideo } from './getVideo';

export const getImageCloudinaryImg = (data, options, included) => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const { id, type } = Array.isArray(copy) && copy.length > 0 ? copy[0] : copy;

  if (copy.length === 0) return;

  const image = getRelationships(id, included);

  if (type === 'media--remote_video') {
    return getVideo(image);
  }

  return {
    type: 'image',
    url: image?.attributes?.computed_cloudinary,
    alt:
      image?.relationships?.field_media_image?.data?.meta?.alt || image?.attributes?.name,
    byline: image?.relationships?.field_media_image?.data?.meta?.title,
  };
};
