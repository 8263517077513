import { getImageCloudinary } from './getImageCloudinary';
import { getVideo } from './getVideo';

export const getMedia = (data, config) => {
  if (!data) return;
  const copy = JSON.parse(JSON.stringify(data));
  const type = Array.isArray(copy) && copy.length > 0 ? copy[0].type : copy?.type;
  if (type === 'media--image') {
    return getImageCloudinary(
      data,
      {
        transformations: {
          format: 'webp',
          resize: {
            type: 'fill',
            height: 1024,
          },
        },
      },
      {},
      { cloudname: config.cloudname },
    );
  }
  if (type === 'media--remote_video') {
    return getVideo(data);
  }
};
