import { getRelationships } from './getRelationships';

// TODO: Add types
export const getVideo = (data: unknown) => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

  if (copy.length === 0) return;

  const video = getRelationships(id, {});
  return {
    type: 'video',
    name: video?.attributes?.name,
    url: video?.attributes?.field_media_oembed_video,
  };
};
