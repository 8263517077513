import { parse } from 'yaml';
import { getRelationships } from './getRelationships';

export const getForm = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

  if (copy.length === 0) return;

  const form = getRelationships(id);

  if (!form) return;

  const elementYaml = form?.attributes?.elements || undefined;
  const parsedYaml = parse(elementYaml);
  const elements = Object.entries(parsedYaml);
  return {
    id: form?.attributes?.drupal_internal__id,
    title: form?.attributes?.title,
    description: form?.attributes?.description,
    elements,
    confirmation: {
      title: form?.attributes?.settings?.confirmation_title || '',
      message: form?.attributes?.settings?.confirmation_message || '',
    },
  };
};
