import revive_payload_client_miWrCHcMEk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.0_@types+node@18.18.3_eslint@8.38.0_typescript@4.9.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_JDtrclBe8W from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.0_@types+node@18.18.3_eslint@8.38.0_typescript@4.9.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_itBp91MfDx from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@1.0.11/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/apps/refresh/.nuxt/components.plugin.mjs";
import unhead_JxQpNq9mUP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.0_@types+node@18.18.3_eslint@8.38.0_typescript@4.9.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_lju8RI6ZSS from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.0_@types+node@18.18.3_eslint@8.38.0_typescript@4.9.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oCxCdUWtJZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.1.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_b53LnXefek from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+algolia@1.9.0_vue@3.3.4/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_pfuBq3GUqk from "/opt/build/repo/node_modules/.pnpm/@nuxt+image-edge@1.0.0-27840416.dc1ed65/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import composition_jFO53NorAu from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.3_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_WhQXfpq6gV from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.3_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_Fx3ieef6B8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.0_@types+node@18.18.3_eslint@8.38.0_typescript@4.9.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import contentful_bOzdIRmqaX from "/opt/build/repo/apps/refresh/plugins/contentful.js";
import rollbar_XnPIYyOzed from "/opt/build/repo/apps/refresh/plugins/rollbar.ts";
import socialShare_gA6PfhsR4E from "/opt/build/repo/apps/refresh/plugins/socialShare.js";
export default [
  revive_payload_client_miWrCHcMEk,
  router_JDtrclBe8W,
  siteConfig_itBp91MfDx,
  components_plugin_KR1HBZs4kY,
  unhead_JxQpNq9mUP,
  prefetch_client_lju8RI6ZSS,
  plugin_oCxCdUWtJZ,
  plugin_b53LnXefek,
  plugin_pfuBq3GUqk,
  composition_jFO53NorAu,
  i18n_WhQXfpq6gV,
  chunk_reload_client_Fx3ieef6B8,
  contentful_bOzdIRmqaX,
  rollbar_XnPIYyOzed,
  socialShare_gA6PfhsR4E
]