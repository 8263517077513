export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"}],"link":[],"style":[],"script":[{"hid":"matomo","data-cookieconsent":"ignore","innerHTML":" var _paq = window._paq = window._paq || [];\n          /* tracker methods like \"setCustomDimension\" should be called before \"trackPageView\" */\n          _paq.push([\"setExcludedReferrers\", [\"setExcludedReferrers\", [\".djurensratt.se\",\"djurensratt.se\",\"interact.djurensratt.se\",\"psp-ecommerce.payex.com\",\"payex.com\",\"ecom.payex.com\",\"scrive.com\",\"payment.quickpay.net\",\"shop.djurensratt.se\",\"insamling.djurensratt.se\",\"djurensratt.end.it\" ]]);\n          _paq.push(['trackPageView']);\n          _paq.push(['enableLinkTracking']);\n          (function() {\n            var u=\"https://djurensratt.matomo.cloud/\";\n            _paq.push(['setTrackerUrl', u+'matomo.php']);\n            _paq.push(['setSiteId', '1']);\n            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n            g.async=true; g.src='//cdn.matomo.cloud/djurensratt.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);\n          })();","async":true,"type":"text/javascript"},{"hid":"matomo-tagmanager","data-cookieconsent":"ignore","innerHTML":"var _mtm = window._mtm = window._mtm || [];\n          _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});\n          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n          g.async=true; g.src='https://cdn.matomo.cloud/djurensratt.matomo.cloud/container_HIn2W9ws.js'; s.parentNode.insertBefore(g,s);","async":true,"type":"text/javascript"},{"src":"https://code.jquery.com/jquery-3.3.1.slim.min.js","type":"text/javascript","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'