import { getRelationships } from './getRelationships';

export const getImageOriginal = (data, included, baseUrl) => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

  if (copy.length === 0) return;
  const image = getRelationships(id, included);

  if (image?.attributes?.computed_original_image) {
    return {
      url: baseUrl
        ? baseUrl + image?.attributes?.computed_original_image
        : image?.attributes?.computed_original_image,
    };
  }
  return undefined;
};
