
import * as cloudinaryRuntime$IZLSp8eseD from '/opt/build/repo/node_modules/.pnpm/@nuxt+image-edge@1.0.0-27840416.dc1ed65/node_modules/@nuxt/image-edge/dist/runtime/providers/cloudinary'
import * as ipxRuntime$dsSj9fGxF9 from '/opt/build/repo/node_modules/.pnpm/@nuxt+image-edge@1.0.0-27840416.dc1ed65/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'
import * as cmsProviderRuntime$IG0FW7Y2Ib from '/opt/build/repo/apps/refresh/providers/cms.js'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1920,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$IZLSp8eseD, defaults: {"baseURL":"https://res.cloudinary.com/klandestino-ab/image/upload"} },
  ['ipx']: { provider: ipxRuntime$dsSj9fGxF9, defaults: {} },
  ['cmsProvider']: { provider: cmsProviderRuntime$IG0FW7Y2Ib, defaults: {"baseURL":"https://res.cloudinary.com/klandestino-ab/image/fetch"} }
}
        