import { getRelationships } from './getRelationships';

export const getLinks = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  if (Array.isArray(copy) && copy.length > 0) {
    const links = copy.map(item => {
      const id = item.id;
      const link = getRelationships(id);
      return {
        type: link?.attributes?.field_link_type,
        title: link?.attributes?.field_link_text,
        url:
          link?.attributes?.field_link_type === 'ext'
            ? link?.attributes?.field_link_ext?.uri
            : link?.attributes?.computed_link_int,
      };
    });
    return links;
  } else {
    const id = copy?.id;
    if (!id) return;
    const link = getRelationships(id);
    return {
      type: link?.attributes?.field_link_type,
      title: link?.attributes?.field_link_text,
      url:
        link?.attributes?.field_link_type === 'ext'
          ? link?.attributes?.field_link_ext?.uri
          : link?.attributes?.computed_link_int,
    };
  }
};
